import {
  Button,
  Container,
  Dialog,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  Stack,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import GrapeVariety from 'library/models/grapeVariety';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateGrapeVarietyMutation } from '../store/grapeVarietyApi';
type Props = {
  setIsModalCreationOpen: (value: boolean) => void;
  isModalCreationOpen: boolean;
};

const DialogGrapeVarietyCreation = (props: Props) => {
  const { t } = useTranslation();
  const { setIsModalCreationOpen, isModalCreationOpen } = props;

  //TODO use isLoading, isError, isSuccess to display information to the user
  const [createGrapeVariety, { isLoading, isError, isSuccess }] = useCreateGrapeVarietyMutation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [grapeVarietyPayload, setGrapeVarietyPayload] = useState<GrapeVariety>({
    cepage_code: '',
    cepage_color: '',
    cepage_complete_name: '',
    cepage_primary_name: '',
  });

  const handleCreateGrapeVariety = async () => {
    try {
      await createGrapeVariety(grapeVarietyPayload);
      setIsModalCreationOpen(false);
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error creating cepage:', error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={isModalCreationOpen}
      onClose={() => {
        setIsModalCreationOpen(false);
      }}
    >
      <DialogTitle>{t('groupevariety.creation')}</DialogTitle>
      <Container>
        <FormControl>
          <InputLabel>{t('groupeVariety.code')}</InputLabel>
          <Input
            onChange={(e) =>
              setGrapeVarietyPayload({
                ...grapeVarietyPayload,
                cepage_code: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl>
          <InputLabel>{t('groupeVariety.colorName')}</InputLabel>
          <Input
            onChange={(e) =>
              setGrapeVarietyPayload({
                ...grapeVarietyPayload,
                cepage_primary_name: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl>
          <InputLabel>{t('groupeVariety.color')}</InputLabel>
          <Input
            onChange={(e) =>
              setGrapeVarietyPayload({
                ...grapeVarietyPayload,
                cepage_color: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl>
          <InputLabel>{t('complete.name.groupeVariety')}</InputLabel>
          <Input
            onChange={(e) =>
              setGrapeVarietyPayload({
                ...grapeVarietyPayload,
                cepage_complete_name: e.target.value,
              })
            }
          />
        </FormControl>
        <Stack direction='row' spacing={2} m={6}>
          <Button variant='contained' color='primary' onClick={() => handleCreateGrapeVariety()}>
            {t('create')}
          </Button>
          <Button onClick={() => setIsModalCreationOpen(false)}>Annuler</Button>
        </Stack>
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message='Note archived'
      />
    </Dialog>
  );
};

export default DialogGrapeVarietyCreation;
