import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Container } from '@mui/material';
import { PlotData } from 'library/models/plot';
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GuardComponent from '../../auth/components/GuardComponent';
import { roleTypes } from '../../auth/roleTypes';
import LoadingScreen from '../../components/loading-screen/LoadingScreen';
import { getCustomerDisplayName } from '../../customers/services/CustomerHelper';
import Page404 from '../../pages/Page404';
import PlotUpdateDialog from './PlotUpdateDialog';

type Props = {
  beforeButton?: React.ReactElement;
  buttonTitle: string;
  onButtonClick?: () => void;
  title: string;
  plots: PlotData[];
  error: boolean;
  isLoading: boolean;
  disabledButton?: boolean;
  enableSelection?: boolean;
  selectedPlots: { [key: string]: boolean };
  setSelectedPlots: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  allowedRoles: roleTypes[];
};

const PlotsList = (props: Props): React.ReactElement => {
  const { t } = useTranslation();
  const {
    setSelectedPlots,
    plots,
    error,
    isLoading,
    onButtonClick,
    buttonTitle,
    disabledButton,
    enableSelection,
    selectedPlots,
  } = props;
  const [updatePlotId, setUpdatePlotId] = useState<string>('');
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const columns = useMemo<MRT_ColumnDef<PlotData>[]>(
    () => [
      {
        header: t('table.header.plot.name'),
        accessorKey: 'internal_plot_code',
        accessorFn: (row) =>
          row.internal_plot_code + ' ' + row.external_plot_name + ' (' + row.surface_ha + ')',
      },
      {
        header: t('table.header.plot.cepage_name'),
        accessorKey: 'cepage_code',
        accessor: 'cepage_code',
      },
      {
        header: t('table.header.plot.customer'),
        accessorKey: 'customer',
        accessor: 'customer',
        accessorFn: (row) => getCustomerDisplayName(row.customer),
      },
      {
        header: t('table.header.plot.farm'),
        accessorKey: 'farm',
        accessor: 'farm',
        accessorFn: (row) => row.farm.farm_name,
      },
      {
        header: t('table.header.farm.actions'),
        accessorKey: 'actions',
        accessor: 'actions',
        accessorFn: (row) => row,
        Cell: (data) => (
          <GuardComponent allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR, roleTypes.VIEWER]}>
            <Button onClick={() => updatePlot(data.row.original.plot_id)}>
              <EditIcon />
            </Button>
          </GuardComponent>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    enableRowSelection: enableSelection,
    selectAllMode: 'all',
    state: { rowSelection: selectedPlots },
    enableFullScreenToggle: false,
    localization: MRT_Localization_FR,
    columns,
    data: plots || [],
    getRowId: (row) => row.plot_id, //give each row a more useful id

    onRowSelectionChange: setSelectedPlots, //connect internal row selection state to your own
  });

  const updatePlot = (plotId: string) => {
    setOpenUpdateModal(true);
    setUpdatePlotId(plotId);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateModal(false);
  };

  if (error) {
    return <Page404 />;
  }

  if (isLoading || !plots) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      {updatePlotId && openUpdateModal && (
        <PlotUpdateDialog
          open={openUpdateModal}
          onClose={handleCloseUpdateDialog}
          plotId={updatePlotId}
        />
      )}

      <Box display='flex' flexDirection={'row'} justifyContent={'right'}>
        <Box p={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          {props.beforeButton}
          {
            <GuardComponent allowedRoles={props.allowedRoles}>
              <Box ml={2}>
                <Button variant='contained' onClick={onButtonClick} disabled={disabledButton}>
                  {buttonTitle}
                </Button>
              </Box>
            </GuardComponent>
          }
        </Box>
      </Box>
      <MaterialReactTable table={table} />
    </Container>
  );
};

export default PlotsList;
