import { Box, Button, Container } from '@mui/material';
import { CustomerData } from 'library/models/customer';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import GuardComponent from '../../auth/components/GuardComponent';
import { roleTypes } from '../../auth/roleTypes';
import LoadingScreen from '../../components/loading-screen/LoadingScreen';
import Page404 from '../../pages/Page404';
import { useGetCustomersQuery, useRefreshCustomersListMutation } from '../customerApi';

/*
 * This component is used to display the list of customers.
 */

const CustomersList = () => {
  const { t } = useTranslation();
  const { data: customers, isError, isLoading } = useGetCustomersQuery();

  const [refreshCustomersList, { isError: isErrorMutation, isLoading: isLoadingMutation }] =
    useRefreshCustomersListMutation();

  const columns = useMemo<MRT_ColumnDef<CustomerData>[]>(
    () => [
      {
        header: t('table.header.customer.name-solution'),
        accessorKey: 'customer_name',
        accessor: 'customer_name',
      },
      {
        header: t('table.header.customer.center'),
        accessorKey: 'icv_center',
        accessor: 'icv_center',
      },
      {
        header: t('table.header.customer.solution_code'),
        accessorKey: 'solution_code',
        accessor: 'solution_code',
      },
      {
        header: t('table.header.customer.address'),
        accessorKey: 'adress',
        accessor: 'adress',
      },
      {
        header: t('table.header.customer.town'),
        accessorKey: 'municipality',
        accessor: 'municipality',
      },
      {
        header: t('table.header.customer.zipCode'),
        accessorKey: 'zip_code',
        accessor: 'zip_code',
      },
    ],
    []
  );

  if (isLoading || isLoadingMutation || !customers) {
    return <LoadingScreen />;
  }

  if (isError || isErrorMutation) {
    return <Page404 />;
  }

  return (
    <Container>
      <Helmet>
        <title>{t('customers')}</title>
      </Helmet>
      <h1>{t('customers')}</h1>
      <GuardComponent allowedRoles={[roleTypes.ADMIN]}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: 2,
          }}
        >
          <Button variant='contained' color='primary' onClick={() => refreshCustomersList()}>
            {t('customers.refreshlist')}
          </Button>
        </Box>
        <MaterialReactTable
          columns={columns}
          data={customers || []}
          enableFullScreenToggle={false}
          localization={MRT_Localization_FR}
        />
      </GuardComponent>
    </Container>
  );
};

export default CustomersList;
