import { Box, Container, Typography } from '@mui/material';
import { PlotData } from 'library/models/plot';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { roleTypes } from '../../auth/roleTypes';
import { UseIsAuthorized } from '../../auth/useHasAuthorisedBy';
import DialogPrint from '../../print/components/DialogPrint';
import PlotList from '../components/PlotList';
import PlotQueryCreator from '../components/PlotQueryCreator';
import PlotsUtils from '../services/PlotsUtils';
import { useGetPlotByFilterQuery } from '../store/plotApi';

const PrintPage = () => {
  const { t } = useTranslation();
  const [selectedPlots, setSelectedPlots] = useState<{ [key: string]: boolean }>({});
  const [openPrintModal, setOPenPrintModal] = useState<boolean>(false);
  const countSelectedPlots = PlotsUtils.countSelectedPlots(selectedPlots);
  const [query, setQuery] = useState<string>('');
  const {
    data: plots,
    error,
    isLoading,
  } = useGetPlotByFilterQuery(query, {
    skip: query.length < 3,
  });
  const isAuthorizedToPrint = UseIsAuthorized([roleTypes.ADMIN, roleTypes.EDITOR]);

  const getSelectedPlots = (): PlotData[] => {
    const plotsSelected: PlotData[] = [];
    if (plots) {
      for (const plot of plots) {
        if (selectedPlots[plot.plot_id]) {
          plotsSelected.push(plot);
        }
      }
    }
    return plotsSelected;
  };

  return (
    <Container>
      <Helmet>
        <title>{t('plots.print.title')}</title>
      </Helmet>
      <Box mb={2}>
        <Typography variant='h3'>{t('plots.print.title')}</Typography>
      </Box>
      <PlotQueryCreator query={query} setQuery={setQuery} />
      <PlotList
        disabledButton={isAuthorizedToPrint && countSelectedPlots === 0}
        onButtonClick={() => setOPenPrintModal(true)}
        buttonTitle={t('button.print.plot')}
        plots={plots ?? []}
        error={!!error}
        isLoading={isLoading}
        title={t('plot.print')}
        enableSelection
        selectedPlots={selectedPlots}
        setSelectedPlots={setSelectedPlots}
        allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR, roleTypes.VIEWER]}
      />
      {openPrintModal && (
        <DialogPrint
          open={openPrintModal}
          onClose={() => setOPenPrintModal(false)}
          plots={getSelectedPlots()}
        />
      )}
    </Container>
  );
};

export default PrintPage;
