import { Box, Container, Typography } from '@mui/material';
import GrapeVariety from 'library/models/grapeVariety';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import GuardComponent from '../../auth/components/GuardComponent';
import { roleTypes } from '../../auth/roleTypes';
import LoadingScreen from '../../components/loading-screen/LoadingScreen';
import Page404 from '../../pages/Page404';
import { useGetGrapeVarietiesQuery } from '../store/grapeVarietyApi';
import ModalCreationCepage from './ModalCreationCepage';

const CepageList = (): React.ReactElement => {
  const { t } = useTranslation();
  const { data: grapeVarieties, error, isLoading } = useGetGrapeVarietiesQuery();
  const [isModalCreationOpen, setIsModalCreationOpen] = React.useState(false);

  const columns = useMemo<MRT_ColumnDef<GrapeVariety>[]>(
    () => [
      {
        header: t('table.header.cepage.shortName'),
        accessorKey: 'cepage_code',
        accessor: 'cepage_code',
      },
      {
        header: t('table.header.cepage.fullName'),
        accessorKey: 'cepage_complete_name',
        accessor: 'cepage_complete_name',
      },
      {
        header: t('table.header.cepage.firstDenomination'),
        accessorKey: 'cepage_primary_name',
        accessor: 'cepage_primary_name',
      },
      {
        header: t('table.header.cepage.color'),
        accessorKey: 'cepage_color',
        accessor: 'cepage_color',
      },
    ],
    []
  );

  if (isLoading || !grapeVarieties) {
    return <LoadingScreen />;
  }

  if (error) {
    return <Page404 />;
  }

  return (
    <Container>
      <Helmet>
        <title>{t('cepages.title')}</title>
      </Helmet>
      <GuardComponent allowedRoles={[roleTypes.ADMIN]}>
        <Box>
          <Typography variant='h3'>{t('cepages.title')}</Typography>
        </Box>
        <ModalCreationCepage
          setIsModalCreationOpen={setIsModalCreationOpen}
          isModalCreationOpen={isModalCreationOpen}
        />
        <Container>
          <MaterialReactTable
            columns={columns}
            data={grapeVarieties || []}
            enableFullScreenToggle={false}
            localization={MRT_Localization_FR}
          />
        </Container>
      </GuardComponent>
    </Container>
  );
};

export default CepageList;
