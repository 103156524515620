import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authPrepareHeaders } from '../../auth/store/AuthPrepareHeaders';
import { getApiBaseUrl } from '../../config-global';
import { Sector } from '../models/types';

export const sectorApi = createApi({
  reducerPath: 'sectorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(),
    prepareHeaders: authPrepareHeaders
  }),
  endpoints: (builder) => ({
    getSectors: builder.query<Sector[], void>({
      query: () => 'sectors',
    }),
    createSector: builder.mutation<Sector, Sector>({
      query: (body) => ({
        url: `sectors`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetSectorsQuery, useCreateSectorMutation } = sectorApi;
