import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FarmData, FarmDataCreation } from 'library/models/farm';
import { authPrepareHeaders } from '../../auth/store/AuthPrepareHeaders';
import { getApiBaseUrl } from '../../config-global';

export const farmApi = createApi({
  reducerPath: 'farmApi',
  tagTypes: ['farms'],
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(),
    prepareHeaders: authPrepareHeaders,
  }),
  endpoints: (builder) => ({
    getFarm: builder.query<FarmData, string>({
      query: (id) => `farms/${id}}`,
      providesTags: ['farms'],
    }),
    getFarms: builder.query<FarmData[], void>({
      query: () => 'farms',
      providesTags: ['farms'],
    }),
    uppdateFarm: builder.mutation<FarmData, FarmDataCreation>({
      query: (body) => ({
        url: `farms`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['farms'],
    }),
    createFarm: builder.mutation<FarmData, FarmDataCreation>({
      query: (body) => ({
        url: `farms`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['farms'],
    }),
  }),
});

export const { useGetFarmQuery, useGetFarmsQuery, useCreateFarmMutation, useUppdateFarmMutation } =
  farmApi;
