import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PlotGroup } from 'library/models/plot';
import { authPrepareHeaders } from '../../auth/store/AuthPrepareHeaders';
import { getApiBaseUrl } from '../../config-global';

export const referentielApi = createApi({
  reducerPath: 'referentielApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(),
    prepareHeaders: authPrepareHeaders
  }),
  endpoints: (builder) => ({
    getReferentiels: builder.query<PlotGroup[], void>({
      query: () => `/plotgroups/`,
    }),
    createReferentiel: builder.mutation<PlotGroup, PlotGroup>({
      query: (body) => ({
        url: `referentiels`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetReferentielsQuery, useCreateReferentielMutation } = referentielApi;
