import {
  Alert,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { PlotData, PlotDataCreation } from 'library/models/plot';
import { CentroidData } from 'library/models/plotCreation.request';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../theme/overrides/CustomIcons';
import { StepUpdateDialog } from '../enums/enums';
import PlotsUtils from '../services/PlotsUtils';
import {
  useCreatePlotMutation,
  useGetGeometryByPlotIdQuery,
  useGetPlotQuery,
  useGetplotGroupByPlotIdQuery,
  useUpdatePlotMutation,
} from '../store/plotApi';
import PlotUpdateAddCodes from './plotUpdate/PlotUpdateAddCodes';
import PlotUpdateConfirmationScreen from './plotUpdate/PlotUpdateConfirmationScreen';
import PlotUpdateSucceed from './plotUpdate/PlotUpdateSucceed';
import TabPanelComponent from './plotUpdate/TabPanelComponent';

type Props = {
  open: boolean;
  onClose: () => void;
  plotId: string;
};

const PlotUpdateDialog = ({ open, onClose, plotId }: Props) => {
  const [updatePlot, { isError, isSuccess }] = useUpdatePlotMutation();
  const [step, setStep] = useState<StepUpdateDialog>(StepUpdateDialog.FORM);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const { data: plotGroups } = useGetplotGroupByPlotIdQuery(plotId);
  const { data: GeometryData } = useGetGeometryByPlotIdQuery(plotId);
  const { data: plot, isLoading } = useGetPlotQuery(plotId);
  const [selectedPlotGroup, setSelectedPlotGroup] = React.useState<{
    [keyof: string]: boolean | undefined;
  } | null>(null);

  const { t } = useTranslation();
  const [plotPayload, setPlotPayload] = React.useState<PlotData | undefined>(undefined);
  const [centroid, setCentroid] = useState<CentroidData | null>(null);

  const [createPlot, { isError: isErrorCreate, isSuccess: isSuccessCreate }] =
    useCreatePlotMutation();

  const handleUpdatePlotToDatabase = (isNewPlot: boolean) => {
    if (!selectedPlotGroup) return;
    const groups: string[] = Object.keys(selectedPlotGroup).filter(
      (key) => selectedPlotGroup[key] === true
    );
    if (plotPayload) {
      if (isNewPlot) {
        setStep(StepUpdateDialog.NEW_PLOT);
      } else {
        updatePlot({
          plot: plotPayload,
          groups: groups,
          centroid: centroid ?? undefined,
        });
      }
    }
  };

  const onPostPlotDatabase = async () => {
    if (selectedPlotGroup) {
      const groups = Object.keys(selectedPlotGroup).filter(
        (key) => selectedPlotGroup[key] === true
      ); // TODO to externalise with updatePlot
      if (plotPayload && centroid) {
        // map payload to PlotDataCreation
        const payload: PlotDataCreation = {
          external_plot_code: plotPayload.external_plot_code,
          internal_plot_code: plotPayload.internal_plot_code,
          previous_plot_id: plotPayload.previous_plot_id,
          solution_code: plotPayload.solution_code,
          surface_ha: plotPayload.surface_ha,
          vintage: plotPayload.vintage,
          farm_id: plotPayload.farm_id,
          active: plotPayload.active,
          cepage_code: plotPayload.cepage_code,
          external_plot_name: plotPayload.external_plot_name,
        };
        await createPlot({
          plot: payload,
          centroid: centroid,
          groups: groups,
        });
      }
    }
  };

  const handleUpdatePlot = () => {
    setStep(StepUpdateDialog.CONFIRMATION);
  };

  useEffect(() => {
    if (open) {
      if ((plot && plotPayload === undefined) || plot?.plot_id !== plotPayload?.plot_id) {
        setPlotPayload(plot);
      }
      if (plotGroups && selectedPlotGroup === null) {
        const selectedPlotGroup: { [keyof: string]: boolean | undefined } = {};
        plotGroups.forEach((plotGroup) => {
          selectedPlotGroup[plotGroup.plot_group_code] = true;
        });
        setSelectedPlotGroup(selectedPlotGroup);
      }
      if (centroid === null && GeometryData) {
        const point = PlotsUtils.wktTOPoint(GeometryData.centroid ?? '');
        setCentroid({
          longitude: point.longitude,
          latitude: point.latitude,
          type: 'Point',
        });
      }
    }
  });

  useEffect(() => {
    if (isSuccess || isSuccessCreate) {
      setOpenSuccess(true);
      setStep(StepUpdateDialog.SUCCEED);
    }
    if (isError || isErrorCreate) {
      setOpenError(true);
    }
  }, [isSuccess, isError, isSuccessCreate, isErrorCreate]);

  const handleSetPlotPayload = (plotPayload: PlotData) => {
    setPlotPayload(plotPayload);
  };

  const handleClose = () => {
    setStep(StepUpdateDialog.FORM);
    setPlotPayload(undefined);
    setSelectedPlotGroup(null);
    setCentroid(null);
    onClose();
  };

  const displayScreen = () => {
    switch (step) {
      case StepUpdateDialog.FORM:
        return (
          <TabPanelComponent
            plotPayload={plotPayload}
            handleUpdatePlot={handleUpdatePlot}
            setPlotPayload={setPlotPayload}
            plotId={plotId}
            handleSetPlotPayload={handleSetPlotPayload}
            isLoading={isLoading}
            centroid={centroid}
            setCentroid={setCentroid}
            setSelectedPlotGroup={setSelectedPlotGroup}
            selectedPlotGroup={selectedPlotGroup}
          />
        );
      case StepUpdateDialog.CONFIRMATION:
        return (
          <PlotUpdateConfirmationScreen handleUpdatePlotToDatabase={handleUpdatePlotToDatabase} />
        );
      case StepUpdateDialog.NEW_PLOT:
        return plotPayload ? (
          <PlotUpdateAddCodes
            plotPayload={plotPayload}
            setPlotPayload={setPlotPayload}
            handlePostPlotToDatabase={onPostPlotDatabase}
          />
        ) : null;
      case StepUpdateDialog.SUCCEED:
        return <PlotUpdateSucceed handleClose={handleClose} />;
      case StepUpdateDialog.ERROR:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={() => setOpenSuccess(false)}>
        <Alert severity='success'>{t('success.parcelle.update')}</Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={() => setOpenError(false)}>
        <Alert severity='error'>{t('error.parcelle.update')}</Alert>
      </Snackbar>
      <DialogTitle component={'div'}>
        <Box sx={{ ml: '10%' }}>
          <Typography component={'div'} variant='h3'>
            {t('update.plots')}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Container>{displayScreen()}</Container>
      </DialogContent>
    </Dialog>
  );
};

export default PlotUpdateDialog;
