import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { roleTypes } from './roleTypes';

export const UseIsAuthorized = (role: roleTypes[]): boolean => {
  const user = useSelector((state: RootState) => state.authentication.user);
  const hasAuthorisedByRole = user && role.includes(user.plotApplicationRole);
  return hasAuthorisedByRole || false;
};

/**
 *   if (hasAuthorisedByRole && user && user.plotApplicationRole === roleTypes.EDITOR) {
    console.log('EDITOR', user.parcelleAdmin);
    return user.parcelleAdmin;
  } else {
    return hasAuthorisedByRole || false;
  }
 */
