import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { IconButtonAnimate } from '../../../components/animate';
import MenuPopover from '../../../components/menu-popover';
import { useSnackbar } from '../../../components/snackbar';

// ----------------------------------------------------------------------

const OPTIONS: { label: string; linkTo: string }[] = [];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
        <Typography variant='body2' sx={{ color: '#49213b', padding: '8px' }} noWrap>
          {user?.firstName} {user?.lastName}
        </Typography>
        <IconButtonAnimate
          onClick={handleOpenPopover}
          sx={{
            p: 0,
            ...(openPopover && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
              },
            }),
          }}
        >
          <Box
            sx={{
              bgcolor: '#49213b',
              borderRadius: '8px',
              color: 'white',
              padding: '4px',
            }}
          >
            {user?.firstName[0]}
            {user?.lastName[0]}
          </Box>
        </IconButtonAnimate>
      </Box>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle2' noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Se déconnecter
        </MenuItem>
      </MenuPopover>
    </>
  );
}
