import { PlotDataCreation } from 'library/models/plot';

class Utils {
  returnYearsOptions = (): number[] => {
    const actualYear = new Date().getFullYear();
    const lastYear = actualYear - 1;
    const nextYear = actualYear + 1;
    return [lastYear, actualYear, nextYear];
  };

  isPlotPayloadDisabled = (plotPayload: PlotDataCreation): boolean => {
    return (
      !plotPayload ||
      plotPayload.solution_code === '' ||
      plotPayload.farm_id === '' ||
      plotPayload.external_plot_code === '' ||
      plotPayload.cepage_code === '' ||
      !plotPayload.vintage
    );
  };
}

export const utils = new Utils();
