import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Container, Dialog, Stack } from '@mui/material';
import { t } from 'i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  number: number;
  vintage: string;
};
const PlotDialogRenewConfirmation = ({ open, onClose, number, vintage }: Props) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <Container>
          <Stack spacing={8} m={8} justifyContent='center' alignItems='center'>
            <CheckCircleIcon color='success' sx={{ fontSize: 100 }} />
            <Box sx={{ fontWeight: 'bold' }}>
              {number} {t('information.plot.renew')} {vintage}
            </Box>
            <Button variant='contained' onClick={onClose}>
              {t('button.close')}
            </Button>
          </Stack>
        </Container>
      </Dialog>
    </>
  );
};

export default PlotDialogRenewConfirmation;
