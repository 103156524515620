import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  handleClose: () => void;
};

const PlotUpdateSucceed = (props: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Stack spacing={8} justifyContent='center' alignItems='center'>
      <CheckCircleIcon color='success' sx={{ fontSize: 100 }} />
      <Typography variant={'h4'}>{t('information.plot.update')}</Typography>
      <Box m={8} p={8}>
        <Button variant='contained' color='primary' onClick={props.handleClose}>
          {t('button.close.return.plotsList')}
        </Button>
      </Box>
    </Stack>
  );
};

export default PlotUpdateSucceed;
