import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepConfirmation } from '../../enums/enums';

type Props = {
  handleUpdatePlotToDatabase: (isNewPlot: boolean) => void;
};

const PlotUpdateConfirmationScreen = (props: Props) => {
  const { handleUpdatePlotToDatabase } = props;
  const [step, setStep] = useState<StepConfirmation>(StepConfirmation.WAIT);

  const onStep = (step: StepConfirmation) => {
    setStep(step);
  };

  const { t } = useTranslation();

  const displayConfirmation = () => {
    switch (step) {
      case StepConfirmation.WAIT:
        return (
          <>
            <Typography align='center'>{t('plot.update.confirmeMessage.WAIT')}</Typography>
            <Stack mt={4} direction={'row'} justifyContent='space-around'>
              <Button onClick={() => onStep(StepConfirmation.NO)}>
                {t('plot.update.confirmeMessage.WAIT.button.NO')}
              </Button>
              <Button variant='contained' onClick={() => onStep(StepConfirmation.YES)}>
                {t('plot.update.confirmeMessage.WAIT.button.YES')}
              </Button>
            </Stack>
          </>
        );
      case StepConfirmation.NO:
        return (
          <>
            <Typography align='center' variant='h4'>
              {t('plot.update.confirmeMessage.NO.title')}
            </Typography>
            <Typography mt={2} align='center'>
              {t('plot.update.confirmeMessage.NO')}
            </Typography>
            <Stack mt={4} direction={'row'} justifyContent='space-around'>
              <Button onClick={() => onStep(StepConfirmation.WAIT)}>{t('plot.button.no')}</Button>
              <Button variant='contained' onClick={() => handleUpdatePlotToDatabase(false)}>
                {t('plot.button.yes')}
              </Button>
            </Stack>
          </>
        );

      case StepConfirmation.YES:
        return (
          <>
            <Typography align='center' variant='h4'>
              {t('plot.update.confirmeMessage.YES.title')}
            </Typography>
            <Typography align='center' mt={2}>
              {t('plot.update.confirmeMessage.YES')}
            </Typography>
            <Stack mt={4} direction={'row'} justifyContent='space-around'>
              <Button onClick={() => onStep(StepConfirmation.WAIT)}>{t('plot.button.no')}</Button>
              <Button variant='contained' onClick={() => handleUpdatePlotToDatabase(true)}>
                {t('plot.button.yes')}
              </Button>
            </Stack>
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <Container>
      <Box m={4}>{displayConfirmation()}</Box>
    </Container>
  );
};

export default PlotUpdateConfirmationScreen;
