import { BaseQueryApi } from "@reduxjs/toolkit/dist/query";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import axios from "../../utils/axios";

export const authPrepareHeaders = (headers: Headers, api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>): MaybePromise<Headers | void> => {
  // Use axios to get the token in order to avoid state propagation
  const axiosAuthorization = axios.defaults.headers.common.Authorization
  if (axiosAuthorization) {
    headers.set('Authorization', axiosAuthorization.toString())
  }

  return headers
}