import { ElementType, Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../auth/pages/LoginPage')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
