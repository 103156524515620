import { Box, Chip, Stack, Typography } from '@mui/material';
import { PlotData } from 'library/models/plot';
import { useTranslation } from 'react-i18next';
import { useGetPlotsOrderByDateQuery } from '../store/plotApi';

type Props = {
  value: 'external_plot_code' | 'internal_plot_code';
  customerId: string;
  vintage: number;
};

const DisplayLastPlots = (props: Props) => {
  const limit = 3;
  const filter = `limit=${limit}&customerId=${props.customerId}&vintage=${props.vintage}`;
  const { data } = useGetPlotsOrderByDateQuery(filter);
  const { t } = useTranslation();

  return (
    <Box>
      <Typography fontSize={11}>
        {props.value === 'external_plot_code'
          ? t('last.three.plot.external.code')
          : t('last.three.plot.internal.code')}
      </Typography>
      <Stack direction='row' spacing={0.5} marginTop={1}>
        {data &&
          data.map((plot: PlotData) => {
            return (
              <Chip label={plot[props.value]} variant='outlined' size='small' key={plot.plot_id} />
            );
          })}
      </Stack>
    </Box>
  );
};

export default DisplayLastPlots;
