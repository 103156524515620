// @mui
import { Stack } from '@mui/material';
// components
import Image from '../../components/image';
//
import { StyledContent, StyledRoot, StyledSection } from './styles';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>

      <StyledSection>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/background/vineyard.png'}
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        />

      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
