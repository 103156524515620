import {
  Alert,
  Box,
  Container,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import GuardComponent from '../../auth/components/GuardComponent';
import { roleTypes } from '../../auth/roleTypes';
import { UseIsAuthorized } from '../../auth/useHasAuthorisedBy';
import PlotDialogRenewConfirmation from '../components/PlotDialogRenewConfirmation';
import PlotList from '../components/PlotList';
import PlotQueryCreator from '../components/PlotQueryCreator';
import PlotsUtils from '../services/PlotsUtils';
import { useGetPlotByFilterQuery, useRenewPlotsMutation } from '../store/plotApi';
import { utils } from '../utils/utils';

const RenewPage = () => {
  const { t } = useTranslation();
  const [selectedPlots, setSelectedPlots] = useState<{ [key: string]: boolean }>({});
  const [query, setQuery] = useState<string>('');
  const countSelectedPlots = PlotsUtils.countSelectedPlots(selectedPlots);
  const {
    data: plots,
    error,
    isLoading,
  } = useGetPlotByFilterQuery(query, {
    skip: query.length < 3,
  });
  const [openDialogRenewConfirmation, setOpenDialogRenewConfirmation] = useState<boolean>(false);
  const [openSnackBarSuccess, setOpenSnackBarSuccess] = useState<boolean>(false);
  const [openSnackBarError, setOpenSnackBarError] = useState<boolean>(false);
  const [vintage, setVintage] = useState<string | number>(new Date().getFullYear());
  const [renewPlots, { error: isError, isSuccess: success }] = useRenewPlotsMutation();
  const returnYearsOptions = utils.returnYearsOptions();
  const isAuthorizedToRenew = UseIsAuthorized([roleTypes.ADMIN, roleTypes.EDITOR]);

  const handleCloseRenewConfirmationDialog = () => {
    setOpenDialogRenewConfirmation(false);
    setSelectedPlots({});
  };

  const renewSelectedPlots = () => {
    const selectedPlotsId: string[] = [];
    for (const key in selectedPlots) {
      if (selectedPlots[key]) {
        selectedPlotsId.push(key);
      }
    }
    renewPlots({ plots: selectedPlotsId, vintage: vintage.toString() });
  };

  useEffect(() => {
    setSelectedPlots({});
  }, [query]);

  useEffect(() => {
    if (success) {
      setOpenDialogRenewConfirmation(true);
      setOpenSnackBarSuccess(true);
    }
    if (isError) {
      setOpenSnackBarError(true);
    }
  }, [success, isError]);

  return (
    <>
      <Helmet>
        <title>{t('plots.renew.title')}</title>
      </Helmet>
      <Snackbar
        open={openSnackBarSuccess}
        autoHideDuration={6000}
        message='Plots renewed successfully'
        onClose={() => setOpenSnackBarSuccess(false)}
      >
        <Alert severity='success'>Plots renewed successfully</Alert>
      </Snackbar>
      <Snackbar
        open={openSnackBarError}
        autoHideDuration={6000}
        message='Error renewing plots'
        onClose={() => setOpenSnackBarError(false)}
      >
        <Alert severity='error'>Error renewing plots</Alert>
      </Snackbar>
      <PlotDialogRenewConfirmation
        onClose={handleCloseRenewConfirmationDialog}
        number={countSelectedPlots}
        vintage={vintage.toString()}
        open={openDialogRenewConfirmation}
      />
      <Container>
        <Box mb={2}>
          <Typography variant='h3'>{t('plots.renew.title')}</Typography>
        </Box>
        <PlotQueryCreator query={query} setQuery={setQuery} />

        <PlotList
          disabledButton={isAuthorizedToRenew && countSelectedPlots === 0}
          beforeButton={
            <GuardComponent allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR]}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                <Typography>
                  {Object.keys(selectedPlots).length} {t('plot.selected.renew')}
                </Typography>
                <Box ml={2}>
                  <Select
                    value={vintage ?? new Date().getFullYear()}
                    onChange={(e) => setVintage(e.target.value)}
                  >
                    {returnYearsOptions.map((year) => {
                      return (
                        <MenuItem value={year} key={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Stack>
            </GuardComponent>
          }
          allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR]}
          onButtonClick={() => renewSelectedPlots()}
          buttonTitle={t('button.validate')}
          plots={plots ?? []}
          error={!!error}
          isLoading={isLoading}
          title={t('plot.renew')}
          enableSelection
          selectedPlots={selectedPlots}
          setSelectedPlots={setSelectedPlots}
        />
      </Container>
    </>
  );
};

export default RenewPage;
