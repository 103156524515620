import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
// @mui
import { Button, Container, Stack, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';

// assets
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ForbiddenIllustration } from '../assets/illustrations';
import { useAuthContext } from '../auth/useAuthContext';

// ----------------------------------------------------------------------

export default function PageDenied() {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { logout } = useAuthContext();
  const handleLogout = async () => {
    try {
      logout();
      window.location.href = 'https://oenobox.icv.fr/';
      //navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  return (
    <>
      <Helmet>
        <title> 403 Page denied</title>
      </Helmet>
      <Container sx={{ width: '100%', height: '100%', margin: 'auto' }}>
        <Stack direction={'column'} alignContent={'center'} alignItems={'center'}>
          <MotionContainer>
            <m.div variants={varBounce().in}>
              <Typography variant='h3' paragraph>
                {t('403.title')}
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>{t('403.subTitle')}</Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <ForbiddenIllustration
                sx={{
                  height: 260,
                  my: { xs: 5, sm: 10 },
                }}
              />
            </m.div>

            <Button size='large' variant='contained' onClick={() => handleLogout()}>
              {t('return.to.oenobox')}
            </Button>
          </MotionContainer>
        </Stack>
      </Container>
    </>
  );
}
