import { Box, Typography } from '@mui/material';
import React from 'react';

type PropsTabPanel = {
  index: number;
  value: number;
  children: React.ReactNode;
};

const TabPanel = ({ index, value, children }: PropsTabPanel) => (
  <div>
    {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

export default TabPanel;
