export default class PlotsUtils {
  public static countSelectedPlots = (
    selectedPlots: { [key: string]: boolean } | undefined
  ): number => {
    let couting = 0;
    for (const key in selectedPlots) {
      if (selectedPlots[key] === true) {
        couting++;
      }
    }
    return couting;
  };

  public static wktTOPoint = (wkt: string) => {
    const pointString = wkt.substring(wkt.indexOf('(') + 1, wkt.indexOf(')'));
    const coordinates = pointString.split(' ').map(parseFloat);
    return {
      longitude: coordinates[0],
      latitude: coordinates[1],
    };
  };
}
