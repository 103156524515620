import jsPDF from 'jspdf';
import { PlotData } from 'library/models/plot';
import qrCode from 'qrcode';

// Core V.2
export class CorePrint {
  private doc = new jsPDF({
    unit: 'mm',
  });

  qrCode = (data: PlotData, x: number, y: number) => {
    const qrCodeX = x;
    const qrCodeY = y;
    const qrCodeData = (data.customer.solution_code ?? '') + '|' + data.internal_plot_code;
    qrCode.toDataURL(qrCodeData, { width: 30, type: 'image/png' }, (err, url) => {
      if (err) throw err;
      this.doc.addImage(url, 'PNG', qrCodeX, qrCodeY, 20, 20);
    });
  };

  calculateTextWidthInMm = (doc: jsPDF, text: string): number => {
    const textWidthInPoints = doc.getTextWidth(text);
    return textWidthInPoints;
  };

  limitTextToWidth = (text: string, fontSize: number): string => {
    const font = 'Courier';
    const doc = new jsPDF();
    doc.setFont(font);
    doc.setFontSize(fontSize);
    let currentText = text;

    while (this.calculateTextWidthInMm(doc, currentText) > 41) {
      currentText = currentText.slice(0, -1);
    }
    if (currentText.length < text.length) {
      currentText = currentText.slice(0, -3).trim() + '…';
    }

    return currentText;
  };

  position = (i: number): { x: number; y: number } => {
    if (i < 8) {
      return {
        x: 0,
        y: i * 37,
      };
    }
    if (i >= 8 && i < 16) {
      return {
        x: 70,
        y: (i - 8) * 37,
      };
    }
    if (i >= 16) {
      if (i > 23) {
        console.error('i', i);
      }
      return {
        x: 140,
        y: (i - 16) * 37,
      };
    }
    return {
      x: 0,
      y: 0,
    };
  };

  label = async (data: PlotData, x: number, y: number) => {
    const rectWidth = 70;

    const rectX = x;
    const rectY = y;

    const posX = x + 5;
    let posY = y + 6;
    this.qrCode(data, rectX + rectWidth - 30, rectY - 1);

    this.doc.setFontSize(8);
    this.doc.setFont('Courier', 'normal');
    this.doc
      .text(this.limitTextToWidth(data.customer.customer_name, 8), posX, posY)
      .setFontSize(10)
      .setFont('Courier', 'bold');
    posY += 3;

    this.doc
      .text(this.limitTextToWidth(data.customer.solution_code, 10), posX, posY)
      .setFontSize(7)
      .setFont('Courier', 'normal');

    posY += 5;

    this.doc.text(this.limitTextToWidth(data.farm.farm_name, 7), posX, posY).setFontSize(8);

    posY += 3;
    this.doc
      .text(this.limitTextToWidth(data.cepage_code, 8), posX, posY)
      .setFontSize(10)
      .setFont('Courier', 'bold');

    posY += 3;

    this.doc
      .text(this.limitTextToWidth(data.external_plot_name ?? '', 7), posX, posY)
      .setFontSize(7)
      .setFont('Courier', 'normal');

    posY += 3;
    this.doc
      .text(data.external_plot_code ?? '', posX, posY)
      .setFontSize(9)
      .setFont('Courier', 'bold');
    posY += 3;

    const positionXInternal = this.doc.getTextWidth(data.internal_plot_code ?? '');
    this.doc
      .text(data.internal_plot_code ?? '', x + rectWidth - positionXInternal - 12.5, posY)
      .setFontSize(8)
      .setFont('Courier', 'bold');

    posY += 5;
    this.doc.text('Menu analyse:', posX, posY);
  };

  grouping = (data: Array<PlotData[]>): Array<PlotData[]> => {
    const plotsToPrint: Array<PlotData[]> = [[]];
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      const plots = data[i];
      for (let j = 0; j < plots.length; j++) {
        const plot = plots[j];
        plotsToPrint[plotsToPrint.length - 1].push(plot);
        if (count === 23) {
          count = 0;
          plotsToPrint.push([]);
        } else {
          count++;
        }
      }
      if (i + 1 < data.length) {
        plotsToPrint.push([]);
        count = 0;
      }
    }
    return plotsToPrint;
  };

  core = (plotsToPrint: Array<PlotData[]>) => {
    const data = this.grouping(plotsToPrint);

    for (let i = 0; i < data.length; i++) {
      const plots = data[i];
      for (let j = 0; j < plots.length; j++) {
        const plot = plots[j];
        const position = this.position(j);
        this.label(plot, position.x, position.y);
      }
      this.doc.addPage();
    }

    this.doc.save(`label-${new Date().toISOString()}.pdf`);
  };
}

export const corePrint = new CorePrint();
