export enum StepUpdateDialog {
  FORM = 'FORM',
  CONFIRMATION = 'CONFIRMATION',
  SUCCEED = 'SUCCEED',
  ERROR = 'ERROR',
  NEW_PLOT = 'NEW_PLOT',
}

export enum StepConfirmation {
  WAIT = 'WAIT',
  NO = 'NO',
  YES = 'YES',
}
