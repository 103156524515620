import { createContext, useCallback, useEffect, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { useDispatch, useSelector } from 'react-redux';
import { getApiBaseUrl } from '../config-global';
import { RootState } from '../redux/store';
import { AuthenticationActionTypes } from './store/AuthReducer';
import { JWTContextType } from './types';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const dispatch = useDispatch();
  const state = useSelector<RootState, RootState['authentication']>((state) => state.authentication);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const userData = await getUserData();

        dispatch({
          type: AuthenticationActionTypes.INITIAL,
          payload: {
            isAuthenticated: true,
            user: userData,
            token: accessToken,
          },
        });
      } else {
        dispatch({
          type: AuthenticationActionTypes.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
            token: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: AuthenticationActionTypes.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
          token: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);


  // getUserData
  const getUserData = useCallback(async () => {

    const response = await axios.get(`${getApiBaseUrl()}authentications/me`);
    const user = response.data;
    return user;
  }, []);

  // LOGIN
  const loginWithToken = useCallback(async (token: string) => {

    setSession(token);

    // Wait for 200ms as sometimes, seems to quick for the server
    await new Promise(r => setTimeout(r, 200));

    const userData = await getUserData();

    dispatch({
      type: AuthenticationActionTypes.LOGIN,
      payload: {
        user: userData,
        token: token,
      },
    });
  }, []);

  // REDIRECT TO SIGNIN PAGE
  const redirectToSignin = useCallback(
    async () => {
      const redirectToSignUrl = `${getApiBaseUrl()}authentications/oenobox/authenticate`;
      window.location.href = redirectToSignUrl;
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {

    setSession(null);

    window.location.href = `https://oenobox.icv.fr/logout?redirect_uri=${window.location.origin}`;

    dispatch({
      type: AuthenticationActionTypes.LOGOUT,
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      loginWithToken,
      redirectToSignin,
      logout,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, loginWithToken, logout, redirectToSignin]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
