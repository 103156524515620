import React from 'react';
import { roleTypes } from '../roleTypes';
import { UseIsAuthorized } from '../useHasAuthorisedBy';

type Props = {
  allowedRoles: roleTypes[];
  children: React.ReactNode;
};

const GuardComponent: React.FC<Props> = (props) => {
  const isAuthorizedToRenew = UseIsAuthorized(props.allowedRoles);

  if (isAuthorizedToRenew) {
    return <>{props.children}</>;
  }
  return null;
};

export default GuardComponent;
