// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  customers: path(ROOTS_DASHBOARD, '/customers'),
  plots: path(ROOTS_DASHBOARD, '/plots'),
  plotsCreation: path(ROOTS_DASHBOARD, '/plots/creation'),
  plotsRenewal: path(ROOTS_DASHBOARD, '/plots/renewal'),
  plotPrint: path(ROOTS_DASHBOARD, '/plots/print'),
  ref: path(ROOTS_DASHBOARD, '/referentiels'),
  grapeVarieties: path(ROOTS_DASHBOARD, '/cepages'),
  farms: path(ROOTS_DASHBOARD, '/farms'),
  services: path(ROOTS_DASHBOARD, '/service'),
};
