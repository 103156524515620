import { Box, Container, Tab, Tabs } from '@mui/material';
import { t } from 'i18next';
import { PlotData } from 'library/models/plot';
import { CentroidData } from 'library/models/plotCreation.request';
import { useState } from 'react';
import LoadingScreen from '../../../components/loading-screen';
import PlotCreationStepTwo from '../plotCreation/PlotCreationStepTwo';
import PlotUpdateStepOne from './PlotUpdateStepOne';
import TabPanel from './TabPanel';
import SaveButton from './saveButton';

type IsChecked = {
  internal_code: boolean;
  external_code: boolean;
};

type PropsTabPanelComponent = {
  plotPayload?: PlotData;
  setPlotPayload: (plotPayload: PlotData) => void;
  plotId: string;
  handleUpdatePlot: () => void;
  handleSetPlotPayload: (plotPayload: PlotData) => void;
  isLoading: boolean;
  centroid: CentroidData | null;
  setCentroid: (centroid: CentroidData) => void;
  setSelectedPlotGroup: (selectedPlotGroup: { [keyof: string]: boolean | undefined }) => void;
  selectedPlotGroup: { [keyof: string]: boolean | undefined } | null;
};

const TabPanelComponent = (props: PropsTabPanelComponent) => {
  const {
    plotPayload,
    handleSetPlotPayload,
    isLoading,
    centroid,
    setCentroid,
    setSelectedPlotGroup,
    selectedPlotGroup,
    handleUpdatePlot,
  } = props;

  const [value, setValue] = useState<number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [isValid, setIsValid] = useState<IsChecked | undefined>(undefined);

  return (
    <Box m={4}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t('information.parcelle.update.information')} />
        <Tab label={t('information.parcelle.update.refAndlocaliation')} />
      </Tabs>
      <TabPanel value={value} index={0} data-testid={'ok'}>
        {plotPayload && !isLoading ? (
          <Container>
            <PlotUpdateStepOne
              plotPayload={plotPayload}
              onPlotPayloadChange={handleSetPlotPayload}
              setIsvalid={setIsValid}
            />
          </Container>
        ) : (
          <LoadingScreen />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {selectedPlotGroup && (
          <PlotCreationStepTwo
            centroid={centroid}
            setCentroid={setCentroid}
            setSelectedPlotGroup={setSelectedPlotGroup}
            selectedPlotGroup={selectedPlotGroup}
          />
        )}
      </TabPanel>

      <SaveButton
        disable={{
          external_code: plotPayload?.external_plot_code === '',
          internal_code: true,
        }}
        plotPayload={plotPayload}
        setPlotPayload={handleSetPlotPayload}
        handleUpdatePlot={handleUpdatePlot}
      />
    </Box>
  );
};
export default TabPanelComponent;
