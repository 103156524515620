import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import GrapeVariety from '../../../../library/models/grapeVariety';
import { authPrepareHeaders } from '../../auth/store/AuthPrepareHeaders';
import { getApiBaseUrl } from '../../config-global';

export const grapeVarietyApi = createApi({
  reducerPath: 'grapeVarietyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(),
    prepareHeaders: authPrepareHeaders,
  }),
  tagTypes: ['GrapeVariety'],
  endpoints: (builder) => ({
    getGrapeVarieties: builder.query<GrapeVariety[], void>({
      providesTags: ['GrapeVariety'],
      query: () => 'cepages',
    }),
    createGrapeVariety: builder.mutation<GrapeVariety, Partial<GrapeVariety>>({
      query: (body) => ({
        url: 'cepages',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});
export const { useGetGrapeVarietiesQuery, useCreateGrapeVarietyMutation } = grapeVarietyApi;
