import { Button, Container, Stack } from '@mui/material';
import { t } from 'i18next';
import { PlotData } from 'library/models/plot';
import GuardComponent from '../../../auth/components/GuardComponent';
import { roleTypes } from '../../../auth/roleTypes';

type PropsSaveButton = {
  plotPayload?: PlotData;
  setPlotPayload: (plotPayload: PlotData) => void;
  handleUpdatePlot: () => void;
  disable?: { internal_code: boolean; external_code: boolean };
};

const SaveButton = (props: PropsSaveButton) => {
  const { plotPayload, setPlotPayload, handleUpdatePlot } = props;
  return (
    <Container>
      <GuardComponent allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR]}>
        <Stack spacing={2} direction='row'>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              if (plotPayload) {
                setPlotPayload({
                  ...plotPayload,
                  active: !plotPayload.active,
                });
              }
            }}
          >
            {plotPayload?.active
              ? t('button.parcelle.update.disable')
              : t('button.parcelle.update.enable')}
          </Button>
          <Button
            disabled={props.disable && props.disable.external_code}
            onClick={() => handleUpdatePlot()}
          >
            {t('button.parcelle.update.update')}
          </Button>
        </Stack>
      </GuardComponent>
    </Container>
  );
};

export default SaveButton;
