export const translationFarmFR = {
  'dialog.farm.creation.title': "Création d'une exploitation",
  addFarm: 'Créer une nouvelle exploitation',
  'dialog.farm.creation.name': 'Nom de l exploitation',
  'dialog.farm.creation.adress': 'Adresse',
  'dialog.farm.creation.adress_other': 'Complément d adresse',
  'dialog.farm.creation.zip_code': 'Code postal',
  'dialog.farm.creation.municipality': 'Commune',
  'dialog.farm.creation.country': 'Pays',
  'button.add.farm': 'Ajouter une exploitation',
  'success.creation.farm': 'Exploitation créée avec succès',
  'error.creation.farm': "Une erreur s'est produite lors de la création de l'exploitation",
  'dialog.farm.creation.customer': 'Client',
  'dialog.farm.creation.active': 'Actif',
  'table.header.farm.actions': 'Modifier',
  'last.three.plot.internal.code': 'Derniers codes interne:',
  'last.three.plot.external.code': 'Derniers codes externe:',
  'return.to.oenobox': 'Retour à Oenobox',
  cepage_code: 'Cépage',
};
