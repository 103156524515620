// @mui
import { Stack, Typography } from '@mui/material';
// auth
// layouts
import LoginLayout from '../../layouts/login';
//
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Logo from '../../components/logo';
import { useAuthContext } from '../useAuthContext';

// ----------------------------------------------------------------------

export default function Login() {

  const [searchParams, setSearchParams] = useSearchParams();
  const { loginWithToken, redirectToSignin } = useAuthContext();

  useEffect(() => {
    if (searchParams.has("token")) {
      const token = searchParams.get("token");
      loginWithToken(token!)

      searchParams.delete('token')
      setSearchParams(searchParams);
    } else {
      redirectToSignin();
    }

  }, []);

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Logo
          sx={{
            textAlign: "left"
          }}
        />
        <Typography variant="h4">Se connecter avec Oenobox</Typography>
      </Stack>

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={false}
        onClick={() => redirectToSignin()}
      >
        Connexion
      </LoadingButton>

    </LoginLayout>
  );
}
