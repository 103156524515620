import {
  Autocomplete,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { Center } from 'library/models/customer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCentersQuery, useGetCustomersByIcvCenterQuery } from '../../customers/customerApi';
import { getCustomerDisplayName } from '../../customers/services/CustomerHelper';
import { useGetReferentielsQuery } from '../../referentiel/store/referentielApi';
import { useGetSectorsQuery } from '../../sector/store/apiSector';
import { useGetVintagesInPlotsQuery } from '../store/plotApi';

type Props = {
  query: string;
  setQuery: (query: string) => void;
};

const PlotQueryCreator = ({ query, setQuery }: Props): React.ReactElement => {
  const { data: vintages } = useGetVintagesInPlotsQuery();
  const { data: centers, isLoading: isLoadingCenters } = useGetCentersQuery();
  const { data: sectors } = useGetSectorsQuery();
  const { data: plotGroups } = useGetReferentielsQuery();

  const { t } = useTranslation();
  const [isActive, setIsactive] = useState<boolean>(true);
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [vintage, setVintage] = useState<string>(`${new Date().getFullYear()}`);
  const [centre, setCentre] = useState<Center>();
  const [customers, setClient] = useState<string[]>([]);
  const [plot_group, setPlotGroup] = useState<string[]>([]);
  const [sector, setSector] = useState<string[]>([]);

  const { data: customersData } = useGetCustomersByIcvCenterQuery(
    (centre && centre.icv_center) ?? '',
    {
      skip: !(centre && centre.icv_center),
    }
  );

  const generateQuery = (): string => {
    let generatedQuery = '?';
    if (isActive) {
      generatedQuery += 'active=true';
    }
    if (isInactive) {
      isActive ? (generatedQuery += '&inactive=true') : (generatedQuery += 'inactive=true');
    }
    generatedQuery += '&vintages=' + vintage;
    generatedQuery += '&centers=' + centre?.icv_center ?? '';
    generatedQuery += '&solution_code=' + customers.join(',');
    generatedQuery += '&plot_group=' + plot_group.join(',');
    generatedQuery += '&sector_code=' + sector.join(',');
    return generatedQuery;
  };

  useEffect(() => {
    if (!isLoadingCenters && centers && centers?.length >= 0) {
      setCentre(centers[0]);
    }
  }, [centers, isLoadingCenters]);

  useEffect(() => {
    setQuery(generateQuery());
  }, [
    isActive,
    isInactive,
    vintage,
    centre,
    customers,
    plot_group,
    setQuery,
    query,
    sector,
    isLoadingCenters,
    centre,
  ]);

  return (
    <Container>
      <FormGroup>
        <Stack spacing={2} direction={'row'} flex={'wrap'} mb={2}>
          {vintages && (
            <Autocomplete
              id='tags-outlined'
              options={vintages ?? []}
              disableClearable
              getOptionLabel={(option) => option.toString()}
              filterSelectedOptions
              sx={{ width: 300 }}
              onChange={(event, value) => setVintage(value.toString())}
              value={Number(vintage)}
              defaultValue={Number(vintage)}
              renderInput={(params) => <TextField {...params} label={t('vintage')} />}
            />
          )}
          {centers && centre && (
            <Autocomplete
              id='tags-outlined'
              options={centers ?? []}
              disableClearable
              getOptionLabel={(option) => option.icv_center}
              filterSelectedOptions
              sx={{ width: 300 }}
              onChange={(event, value) => {
                value && setCentre(value);
              }}
              value={centre}
              renderInput={(params) => <TextField {...params} label={t('center')} />}
            />
          )}
        </Stack>
        <Stack spacing={2} direction={'row'} flex={'wrap'}>
          {customersData && centre && (
            <Autocomplete
              id='tags-outlined'
              multiple
              options={
                customersData?.filter((customer) => customer.icv_center === centre?.icv_center) ??
                []
              }
              getOptionLabel={(customer) => getCustomerDisplayName(customer)}
              filterSelectedOptions
              onChange={(event, value) => {
                setClient(value.map((c) => c.solution_code?.toString() ?? ''));
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label={t('customers')} />}
            />
          )}
        </Stack>
        <Stack direction={'row'} spacing={2} mt={2}>
          {isActive !== null && (
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isActive}
                    onChange={() => {
                      setIsactive(!isActive);
                    }}
                  />
                }
                label={t('active')}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={isInactive} onChange={() => setIsInactive(!isInactive)} />
                }
                label={t('inactive')}
              />
            </Stack>
          )}
          {plotGroups && (
            <Autocomplete
              id='tags-outlined'
              multiple
              options={plotGroups ?? []}
              getOptionLabel={(option) => option.plot_group_name}
              filterSelectedOptions
              onChange={(event, value) => {
                setPlotGroup(value.map((r) => r.plot_group_code));
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label={t('referentiel')} />}
            />
          )}
          {sectors && (
            <Autocomplete
              id='tags-outlined'
              options={sectors ?? []}
              multiple
              getOptionLabel={(option) => option.sector_name}
              filterSelectedOptions
              sx={{ width: 300 }}
              onChange={(event, value) => {
                setSector(value.map((s) => s.sector_code));
              }}
              renderInput={(params) => <TextField {...params} label={t('sector')} />}
            />
          )}
        </Stack>
      </FormGroup>
    </Container>
  );
};

export default PlotQueryCreator;
