import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PlotHasCreated = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Stack spacing={8} justifyContent='center' alignItems='center'>
      <CheckCircleIcon color='success' sx={{ fontSize: 100 }} />
      <Box sx={{ fontWeight: 'bold' }}>{t('information.plot.creation')}</Box>
    </Stack>
  );
};

export default PlotHasCreated;
