//ENTITIES
export const PLOT_ID = "plotId";
export const PLOTS = "plots";
export const RENEW = "renew";
export const GROUPS = "groups";
export const FILTERS = "filters";
export const VINTAGES = "vintages";
export const GEOMETRY = "geometry";
export const CUSTOMERS = "customers";
export const CUSTOMER_ID = "customerId";
export const PLOT_BULK = "/bulk";

//PLOTS
//Back
export const PATH_FILTERS = `/${FILTERS}`;
export const PARAM_PLOT_ID = `/:${PLOT_ID}`;
export const PATH_VINTAGES = `/${VINTAGES}`;
export const PARAM_CUSTOMER_ID = `:${CUSTOMER_ID}`;
export const PATH_PLOTS_ID_RENEW = `/${RENEW}`;
export const PATH_PLOT_ID_GROUPS = `${PARAM_PLOT_ID}/${GROUPS}`;
export const PATH_PLOT_ID_GEOMETRY = `${PARAM_PLOT_ID}/${GEOMETRY}`;
export const PATH_PLOT_ID_CUSTOMERS = `${PARAM_PLOT_ID}/${CUSTOMERS}`;
export const PATH_PLOT_CUSTOMERS_CUSTOMERS_ID = `/${CUSTOMERS}/${PARAM_CUSTOMER_ID}`;
export const PATH_PLOT_CHECK_EXIST = `/check-exist`;
export const PATH_PLOT_ORDER_BY_DATE = `/order-by-date`;
//Front
export const BASE_PLOTS_FRONT = "/plots";

// QUERY
export const VINTAGE = "vintage";
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const SECTOR_CODE = "sector_codes";
export const CUSTOMER_IDS = "customer_ids";
export const PAGE = "page";
