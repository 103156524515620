import { Box, Container, Typography } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { roleTypes } from '../../auth/roleTypes';
import PlotList from '../components/PlotList';
import PlotQueryCreator from '../components/PlotQueryCreator';
import PlotCreation from '../components/plotCreation/PlotCreation';
import { useGetPlotByFilterQuery } from '../store/plotApi';

const CreationPage = () => {
  const { t } = useTranslation();
  const [selectedPlots, setSelectedPlots] = useState<{ [key: string]: boolean }>({});
  const [openModalCreation, setOpenModalCreation] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const {
    data: plots,
    error,
    isLoading,
  } = useGetPlotByFilterQuery(query, {
    skip: query.length < 3,
  });

  return (
    <Container>
      <Helmet>
        <title>{t('plot.list.title')}</title>
      </Helmet>
      <Box mb={2}>
        <Typography variant='h3'>{t('plot.list.title')}</Typography>
      </Box>
      <PlotQueryCreator query={query} setQuery={setQuery} />
      <PlotList
        onButtonClick={() => setOpenModalCreation(true)}
        buttonTitle={t('button.add.newPlot')}
        plots={plots ?? []}
        error={!!error}
        isLoading={isLoading}
        title={t('plot.creation')}
        selectedPlots={selectedPlots}
        setSelectedPlots={setSelectedPlots}
        allowedRoles={[roleTypes.ADMIN, roleTypes.EDITOR]}
      />
      <PlotCreation
        setOpenModalCreation={setOpenModalCreation}
        openModalCreation={openModalCreation}
      />
    </Container>
  );
};

export default CreationPage;
