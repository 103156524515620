import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Center, CustomerData } from 'library/models/customer';
import { authPrepareHeaders } from '../auth/store/AuthPrepareHeaders';
import { getApiBaseUrl } from '../config-global';

export const customerApi = createApi({
  tagTypes: ['customers'],
  reducerPath: 'customerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiBaseUrl(),
    prepareHeaders: authPrepareHeaders,
  }),
  endpoints: (builder) => ({
    getCustomer: builder.query<CustomerData, string>({
      query: (id) => `customers/${id}}`,
    }),
    getCustomers: builder.query<CustomerData[], void>({
      query: () => 'customers',
    }),
    getCustomersByIcvCenter: builder.query<CustomerData[], string>({
      query: (icv_center) => `customers/icv/${icv_center}`,
    }),
    getCenters: builder.query<Center[], void>({
      query: () => 'customers/centers',
    }),
    refreshCustomersList: builder.mutation<CustomerData[], void>({
      query: () => 'customers/refreshCustomers',
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useGetCentersQuery,
  useGetCustomersByIcvCenterQuery,
  useRefreshCustomersListMutation,
} = customerApi;
