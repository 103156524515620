import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { PlotData } from 'library/models/plot';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { corePrint } from '../core/print';
import _ from 'lodash';
import { CorePrint } from '../core/printCore';

type Props = {
  open: boolean;
  onClose: () => void;
  plots: PlotData[];
};

type Filter = {
  id: number;
  title: string;
};

const typeOfSort = [
  'solution_code',
  'internal_plot_code',
  'external_plot_code',
  'farm_name',
  'sector_code',
  'municipality',
  'cepage_code',
];

const DialogPrint = (props: Props) => {
  const { t } = useTranslation();
  const { open, onClose, plots } = props;
  const [numberOfRepetiion, setNumberOfRepetiion] = useState<string>('1');
  const [sort, setSort] = useState<{ [key: number]: string }>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [jumpKey, setJumpkey] = useState<string>('solution_code');

  const sortPlot = (plots: PlotData[]) => {
    if (plots) {
      const sortedPlots = plots.sort((a, b) => {
        let result = 0;

        for (let i = 0; i < Object.keys(sort).length; i++) {
          let key = Object.values(sort)[i];
          if (key === 'farm_name') {
            key = 'farm.farm_name';
          }
          if (_.get(a, key) < _.get(b, key)) result = -1;

          if (_.get(a, key) > _.get(b, key)) result = 1;
        }
        return result;
      });
      return sortedPlots;
    }
    return [];
  };

  function toUngrouping(plots: PlotData[]): Array<PlotData[]> {
    const groups: { [key: string]: PlotData[] } = {};

    let path = jumpKey;
    if (path === 'farm_name') {
      path = 'farm.farm_name';
    }
    plots.forEach((item) => {
      const keyValue = _.get(item, path);
      if (!groups[keyValue]) {
        groups[keyValue] = [];
      }
      for (let i = 0; i < Number(numberOfRepetiion); i++) {
        groups[keyValue].push(item);
      }
    });

    const result: PlotData[][] = Object.values(groups);
    return result;
  }

  const print = (): void => {
    if (plots) {
      const corePrint: CorePrint = new CorePrint();
      const sortedPlots = sortPlot(plots);
      const ungrouping = toUngrouping(sortedPlots);
      corePrint.core(ungrouping);
    }
  };

  const handleAddfilter = () => {
    const number = Math.floor(Math.random() * 1000);
    setFilters([...filters, { id: number, title: '' }]);
  };

  const handleDeleteFilter = (index: number) => {
    const newFilters = filters.filter((filter) => filter.id !== index);
    const newSort: {
      [key: number]: string;
    } = {};
    Object.keys(sort).forEach((key) => {
      if (parseInt(key) !== index) {
        newSort[parseInt(key)] = sort[parseInt(key)];
      }
    });
    setSort(newSort);
    setFilters(newFilters);
  };

  const handleClose = () => {
    setFilters([]);
    setSort({});
    onClose();
  };

  return (
    <Dialog
      maxWidth='lg'
      open={open}
      scroll='paper'
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{t('print.dialog.title')}</DialogTitle>
      <DialogContent>
        <Stack>
          <Box>
            <Box mt={2}>
              <Card sx={{ minWidth: 400 }}>
                <CardContent>
                  <Typography variant='subtitle1' component='div'>
                    {t('print.dialog.filter.by')}
                  </Typography>
                  <Box m={2}>
                    {Object.keys(filters).length > 0 &&
                      filters.map((filter, i) => (
                        <Stack key={filter.id} direction='row' spacing={2} alignItems='center'>
                          <FormControl sx={{ m: 1, width: '30%', minWidth: '180px' }}>
                            <InputLabel id='millesim'>{t('select')}</InputLabel>
                            <Select
                              onChange={(e) => {
                                setSort({
                                  ...sort,
                                  [filter.id]: e.target.value as string,
                                });
                              }}
                            >
                              {typeOfSort.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                  {t([item])}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Button onClick={() => handleDeleteFilter(filter.id)}>
                            <CloseIcon />
                          </Button>
                        </Stack>
                      ))}
                  </Box>
                </CardContent>
                <CardActions>
                  <Box m={2}>
                    <Button variant={'contained'} onClick={() => handleAddfilter()}>
                      {t('dialog.print.button.add.filter')}
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Box>
            <Box mt={2}>
              <Card sx={{ minWidth: 400 }}>
                <CardContent>
                  <Typography variant='subtitle1' component='div'>
                    {t('print.dialog.nb.etiquette')}
                  </Typography>
                  <Box m={4}>
                    <FormControl sx={{ m: 1, width: '25%', minWidth: '100px' }}>
                      <InputLabel id='millesim'>{t('print.dialog.nb.etiquette')}</InputLabel>
                      <Input
                        value={numberOfRepetiion}
                        onChange={(e) => setNumberOfRepetiion(e.target.value)}
                        type='number'
                      />
                    </FormControl>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={2}>
              <Card sx={{ minWidth: 400 }}>
                <CardContent>
                  <Typography variant='subtitle1'>{t('dialog.print.jump.page')}</Typography>
                  <Box m={2}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                      <Typography>{t('print.dialog.nb.etiquette.text')}</Typography>
                      <FormControl sx={{ m: 1, width: '30%', minWidth: '180px' }}>
                        <InputLabel id='millesim'>{t('print.dialog.nb.etiquette.text')}</InputLabel>
                        <Select onChange={(e) => setJumpkey(e.target.value)} value={jumpKey}>
                          {typeOfSort.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {t([item])}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>{t('button.cancel')}</Button>
        <Button variant={'contained'} onClick={() => print()}>
          {t('dialog.print.button.print')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPrint;
