import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CentroidData } from 'library/models/plotCreation.request';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NumericFormatCustom from '../../../components/text-field/TextField';
import { useGetReferentielsQuery } from '../../../referentiel/store/referentielApi';

type Props = {
  centroid: CentroidData | null;
  setCentroid: (centroid: CentroidData) => void;
  setSelectedPlotGroup: (selectedPlotGroup: { [keyof: string]: boolean | undefined }) => void;
  selectedPlotGroup: { [keyof: string]: boolean | undefined };
};

const PlotCreationStepTwo = ({
  setSelectedPlotGroup,
  selectedPlotGroup,
  centroid,
  setCentroid,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { data: plotGroups } = useGetReferentielsQuery();

  useEffect(() => {
    if (plotGroups && Object.keys(selectedPlotGroup).length === 0) {
      const selectedPlotGroup: { [keyof: string]: boolean | undefined } = {};
      plotGroups.forEach((plotGroup) => {
        selectedPlotGroup[plotGroup.plot_group_code] = false;
      });
      setSelectedPlotGroup(selectedPlotGroup);
    }
  }, [plotGroups, selectedPlotGroup, setSelectedPlotGroup]);

  return (
    <Container>
      <Stack direction='column'>
        <Typography m={4} variant='h4'>
          {t('referential')}
        </Typography>
        {plotGroups &&
          Object.keys(selectedPlotGroup).length !== 0 &&
          plotGroups.map((plotGroup) => (
            <FormControlLabel
              key={plotGroup.plot_group_name}
              control={
                <Checkbox
                  checked={selectedPlotGroup[plotGroup.plot_group_code]}
                  value={selectedPlotGroup[plotGroup.plot_group_code]}
                  onClick={() =>
                    setSelectedPlotGroup({
                      ...selectedPlotGroup,
                      [plotGroup.plot_group_code]: !selectedPlotGroup[plotGroup.plot_group_code],
                    })
                  }
                />
              }
              label={plotGroup.plot_group_name}
            />
          ))}
      </Stack>
      <Typography m={4} variant='h4'>
        {t('geographicCoordinates')}
      </Typography>
      <Stack direction='column' spacing={4}>
        <Stack direction='row' spacing={4}>
          <FormControl sx={{ mr: 1, width: '10%' }}>
            <TextField
              label={t('longitudeY')}
              value={centroid?.longitude ?? 0}
              onChange={(e) =>
                centroid
                  ? setCentroid({ ...centroid, longitude: parseFloat(e.target.value) })
                  : setCentroid({
                      longitude: parseFloat(e.target.value),
                      latitude: 0,
                    } as CentroidData)
              }
              name='numberformat'
              id='formatted-numberformat-input'
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              variant='standard'
            />
          </FormControl>
          <FormControl sx={{ width: '10%' }}>
            <TextField
              label={t('latitudeX')}
              value={centroid?.latitude ?? 0}
              onChange={(e) =>
                centroid
                  ? setCentroid({ ...centroid, latitude: parseFloat(e.target.value) })
                  : setCentroid({
                      longitude: 0,
                      latitude: parseFloat(e.target.value),
                    } as CentroidData)
              }
              name='numberformat'
              id='formatted-numberformat-input'
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              variant='standard'
            />
          </FormControl>
        </Stack>
      </Stack>
    </Container>
  );
};
export default PlotCreationStepTwo;
