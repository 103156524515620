import { ActionMapType, AuthStateType, AuthUserType } from '../types';

export enum AuthenticationActionTypes {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [AuthenticationActionTypes.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
    token: string;
  };
  [AuthenticationActionTypes.LOGIN]: {
    user: AuthUserType;
    token: string;
  };
  [AuthenticationActionTypes.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

export const authInitialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  token: null,
};

export const authReducer = (
  state: AuthStateType = authInitialState,
  action: ActionsType
): AuthStateType => {
  if (action.type === AuthenticationActionTypes.INITIAL) {
    const user: any = {
      ...action.payload.user,
    };

    if (
      action.payload.user &&
      action.payload.user.plotApplicationRole === 'EDITOR' &&
      action.payload.user.parcelleAdmin
    ) {
      user.plotApplicationRole = 'ADMIN';
    }
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: user,
      token: action.payload.token,
    };
  }
  if (action.type === AuthenticationActionTypes.LOGIN) {
    const user: any = {
      ...action.payload.user,
    };

    if (
      action.payload.user &&
      action.payload.user.plotApplicationRole === 'EDITOR' &&
      action.payload.user.parcelleAdmin
    ) {
      user.plotApplicationRole = 'ADMIN';
    }
    return {
      ...state,
      isAuthenticated: true,
      user: user,
    };
  }
  if (action.type === AuthenticationActionTypes.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      token: null,
    };
  }
  return state;
};
