import AddIcon from '@mui/icons-material/Add';
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { PlotDataCreation } from 'library/models/plot';
import { CentroidData } from 'library/models/plotCreation.request';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '../../../components/loading-screen/LoadingScreen';
import { CloseIcon } from '../../../theme/overrides/CustomIcons';
import { useCreatePlotMutation } from '../../store/plotApi';
import { utils } from '../../utils/utils';
import { IsChecked } from '../plotUpdate/PlotUpdateStepOne';
import PlotCreationStepOne from './PlotCreationStepOne';
import PlotCreationStepTwo from './PlotCreationStepTwo';
import PlotHasCreated from './PlotHasCreated';
import { Step } from './Step.enum';

type Props = {
  setOpenModalCreation: (openModalCreation: boolean) => void;
  openModalCreation: boolean;
};

const emptyPlotPayload: PlotDataCreation = {
  external_plot_code: '',
  external_plot_name: '',
  internal_plot_code: '',
  surface_ha: 0,
  vintage: new Date().getFullYear(),
  solution_code: '',
  farm_id: '',
  active: true,
  cepage_code: '',
};

const PlotCreation = (props: Props) => {
  const { setOpenModalCreation, openModalCreation } = props;
  const [step, setStep] = useState(Step.STEP_ONE);
  const [isSnackbarError, setIsSnackbarError] = useState<boolean>(false);
  const [isSnackBarSuccess, setSnackBarSuccess] = useState<boolean>(false);
  const [selectedPlotGroup, setSelectedPlotGroup] = React.useState<{
    [keyof: string]: boolean | undefined;
  }>({});

  const { t } = useTranslation();
  const [plotPayload, setPlotPayload] = React.useState<PlotDataCreation>(emptyPlotPayload);
  const [center, setCenter] = useState<string>('');

  const onClearForm = () => {
    setCenter('');
    setPlotPayload(emptyPlotPayload);
    setCentroid({
      longitude: 0,
      latitude: 0,
      type: 'point',
    });
    setSelectedPlotGroup({});
  };

  const onCancel = () => {
    onClearForm();
    setStep(Step.STEP_ONE);
    setOpenModalCreation(false);
  };
  const [isValid, setIsValid] = useState<IsChecked | undefined>(undefined);

  const validCheck = isValid && !isValid.external_code && !isValid.internal_code;
  const isDisabled = utils.isPlotPayloadDisabled(plotPayload) || !validCheck;
  const [createPlot, { isLoading, isError, isSuccess }] = useCreatePlotMutation();

  const [centroid, setCentroid] = useState<CentroidData>({
    longitude: 0,
    latitude: 0,
    type: 'point',
  });

  const handleCreatePlot = async () => {
    const groups = Object.keys(selectedPlotGroup).filter((key) => selectedPlotGroup[key] === true); // TODO to externalise with updatePlot
    if (plotPayload) {
      await createPlot({
        plot: plotPayload,
        centroid: centroid,
        groups: groups,
      });
    }
  };

  useEffect(() => {
    if (isError) {
      setIsSnackbarError(true);
    }
    if (isSuccess) {
      setStep(Step.VALIDATED);
      setSnackBarSuccess(true);
    }
  }, [isError, isSuccess]);

  const selectedStepButtons = () => {
    switch (step) {
      case Step.STEP_TWO:
        return (
          <Stack direction='row' spacing={4}>
            <Button color='primary' onClick={() => setStep(Step.STEP_ONE)}>
              {t('previous')}
            </Button>
            <Button variant='contained' onClick={handleCreatePlot}>
              {t('validate')}
            </Button>
          </Stack>
        );
      case Step.VALIDATED:
        return <></>;
      default:
        return (
          <Stack direction='row' spacing={4}>
            <Button onClick={() => onCancel()}>{t('cancel')}</Button>
            <Button
              disabled={isDisabled}
              variant='contained'
              color='primary'
              onClick={() => setStep(Step.STEP_TWO)}
            >
              {t('next')}
            </Button>
          </Stack>
        );
    }
  };

  const selectedStep = () => {
    switch (step) {
      case Step.STEP_TWO:
        return (
          <>
            <PlotCreationStepTwo
              setSelectedPlotGroup={setSelectedPlotGroup}
              selectedPlotGroup={selectedPlotGroup}
              centroid={centroid}
              setCentroid={setCentroid}
            />
          </>
        );
      case Step.VALIDATED:
        return (
          <Container sx={{ marginTop: 8 }}>
            <PlotHasCreated />
            <Container>
              <Stack width={'100%'} mt={8} justifyContent='space-around' direction='row'>
                <Button onClick={() => onCancel()} variant='contained'>
                  {t('button.plotValidation.returnToPlotList')}
                </Button>
                <Button
                  onClick={() => {
                    onClearForm();
                    setStep(Step.STEP_ONE);
                    setPlotPayload(emptyPlotPayload);
                  }}
                  variant='contained'
                  color='primary'
                  sx={{ mr: 4 }}
                  startIcon={<AddIcon />}
                >
                  {t('button.plotValidation.add.newPlot')}
                </Button>
              </Stack>
            </Container>
          </Container>
        );
      default:
        return (
          <>
            <PlotCreationStepOne
              setCenter={setCenter}
              setIsValid={setIsValid}
              center={center}
              setStep={setStep}
              plotPayload={plotPayload}
              onPlotPayloadChange={setPlotPayload}
            />
          </>
        );
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <Dialog open={openModalCreation} fullScreen maxWidth='xl' sx={{ p: 12 }}>
        <DialogTitle>
          <Box sx={{ ml: '10%' }}>
            <Typography variant='h3'>{t('plot.creation')}</Typography>
            <IconButton
              aria-label='close'
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>{selectedStep()}</DialogContent>
        <DialogActions>{selectedStepButtons()}</DialogActions>
      </Dialog>
      <Snackbar
        open={isSnackbarError}
        onClose={() => setIsSnackbarError(false)}
        autoHideDuration={3000}
      >
        <Alert severity='error' sx={{ width: '100%' }}>
          {t('error.plot.creation')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isSnackBarSuccess}
        onClose={() => setSnackBarSuccess(false)}
        autoHideDuration={3000}
      >
        <Alert severity='success' sx={{ width: '100%' }}>
          {t('success.plot.creation')}
        </Alert>
      </Snackbar>
    </Container>
  );
};
export default PlotCreation;
